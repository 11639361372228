<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { PropType } from 'vue'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { EmitsEnum } from '~~/src/constants/emits'
import { TextSizes } from '~~/src/constants/textSizes'
import { IconSizes } from '~~/src/constants/iconSizes'
import { useToggle } from '~~/src/hooks/useToggle'
import { IRange } from '~~/src/types/IRange'
import { utilDate } from '~~/src/utils/utilDate'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'

const props = defineProps({
	data: {
		type: Object as PropType<IRange<Date>>,
		default: { from: undefined, to: undefined },
	},
	startDate: { type: Date },
	endDate: { type: Date },
	disabledDates: { type: Object as PropType<any[]>, default: () => [] },
	hasError: { type: Boolean, default: false },
	errorMessage: { type: String as PropType<TranslationKey> },
	hintMessage: { type: String as PropType<TranslationKey> },
	disabled: Boolean,
	testIds: {
		type: Object as PropType<{
			'date-picker'?: string
			field?: string
		}>,
		default: () => ({}),
	},
})
const { hasError, errorMessage, data, startDate, hintMessage, disabled, disabledDates, endDate, testIds } =
	toRefs(props)
const currentLocale = useLocale().currentLocale

// format data for v-calendar
const editableData = ref({
	start: data.value.from,
	end: data.value.to,
})
watch(data, () => {
	editableData.value = { start: data.value.from, end: data.value.to }
})

// handle values update
const emit = defineEmits([EmitsEnum.Change])
const onModelUpdate = (val: any) => {
	emit(EmitsEnum.Change, { from: val.start, to: val.end })
	setToggle(false)
}

// handle the text
const text = computed(() => utilDate.formatDateRange({ from: editableData.value.start, to: editableData.value.end }))

// on click outside, close menu
const { toggleValue, toggle: confirmToggle, setToggle } = useToggle()
const toggle = () => {
	if (!disabled.value) {
		confirmToggle()
	}
}
const target = ref(null)
onClickOutside(target, () => setToggle(false))

const errorClasses = computed(() => (hasError.value ? 'border-error-500' : 'border-sky-200'))
const disabledClasses = computed(() =>
	disabled.value ? 'bg-dark-blue-100 text-dark-blue-300' : 'cursor-pointer bg-sky-50'
)

const showHint = ref(false)
</script>

<template>
	<div ref="target">
		<div
			@click="toggle()"
			class="flex w-full items-center justify-between rounded-md border px-4 py-2 text-sm"
			:class="[errorClasses, disabledClasses].join(' ')"
		>
			<div class="flex gap-2">
				<CommonIcon
					:icon-name="SpSvg.BasicCalendar"
					:fill="disabled ? 'fill-dark-blue-400' : 'fill-sky-600'"
					:icon-size="IconSizes.S"
				/>
				<span>
					{{ text }}
				</span>
			</div>
			<CommonIcon v-if="hasError" :icon-name="SpSvg.BasicErrorCircle" fill="fill-error-500" />
			<CommonTooltip
				v-if="!hasError && hintMessage != undefined"
				:show="showHint"
				:text="TranslationKeys.CREATION_DATE_EXPLAINATION"
			>
				<CommonIcon
					:icon-name="SpSvg.BasicHelp"
					:icon-size="IconSizes.S"
					fill="fill-dark-blue-700"
					@mouseenter="showHint = true"
					@mouseleave="showHint = false"
				/>
			</CommonTooltip>
		</div>
		<div v-if="toggleValue" class="absolute z-10 translate-y-2">
			<v-date-picker
				v-model="editableData"
				is-range
				v-on:update:modelValue="onModelUpdate"
				:min-date="startDate"
				:max-date="endDate"
				:disabled-dates="disabledDates"
				:locale="currentLocale"
				:data-testid="testIds?.['date-picker']"
			/>
		</div>
		<CommonText
			v-if="hasError && errorMessage != undefined"
			:text="errorMessage"
			class="whitespace-normal text-error-400"
			:text-size="TextSizes.BODY_REGULAR"
		/>
	</div>
</template>
